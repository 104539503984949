.review-detail--container {
    .review-detail--wrap {
        max-width: 950px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 16px;
        padding-bottom: 16px;
        @media (max-width: 1000px) {
            flex-direction: column-reverse;
        }
    }
    .team-note-card--container {
        .team-note--item {
            display: flex;
            align-items: stretch;
            &:hover {
                background-color: #f7f7f7;
            }
            @media (max-width: 450px) {
                flex-wrap: wrap;
                border-bottom: 1px solid #e3e3e3;
                &:last-child {
                    border-bottom: none;
                }
                .team-note--item_time {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    justify-content: start;
                    padding-bottom: 0;
                    border: none;
                    font-size: 15px;
                    &::before {
                        display: none;
                    }
                }
            }
            & > div {
                flex-grow: 1;
            }
            &_time,
            &_action {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                position: relative;
                padding: 6px 8px;
            }
            &_content {
                padding: 6px 8px;
                width: 65%;
                box-sizing: border-box;
                @media (max-width: 450px) {
                    word-break: break-all;
                    font-size: 15px;
                }
            }
            &_time {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                border-right: 1px solid #e3e3e3;
                width: 20%;
                min-width: 95px;
                &::before {
                    content: "";
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    border: 1px solid #e3e3e3;
                    background-color: #ffffff;
                    right: 0;
                    top: 50%;
                    position: absolute;
                    transform: translate(50%, -50%);
                }
            }
            &_action {
                flex-direction: row;
                justify-content: flex-end;
                width: 15%;
                min-width: 50px;
            }
        }
    }

    .review-detail--primary {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 1000px) {
            flex-wrap: wrap;
            width: 100% !important;
            max-width: unset !important;
        }
    }

    .review-detail--secondary {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 1000px) {
            width: 100% !important;
            max-width: unset !important;
            .review-info-card--container,
            .customer-info-card--container {
                width: 100%;
            }
            #customer {
                &-name {
                    max-width: unset;
                }
                &-email {
                    max-width: unset;
                }
            }
        }
        .more-review--card {
            .more-review__review--content {
                display: -webkit-box; 
                -webkit-line-clamp: 3; 
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .more-review__review--item{
                    &:hover{
                    cursor: pointer;
                    .more-review__review--product-title{
                        text-decoration: underline;
                    }
                }
            }
            .load-more-review{
                &:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
