// @font-face {
//     font-family: "Inter";
//     src: url("../fonts/Inter/Inter-VariableFont_slnt\,wght.woff2")
//         format("woff2");
//     font-style: normal;
// }

// custom spacing
$space-250: 10px;
$space-350: 14px;
$space-450: 18px;
$space-550: 22px;

body,
html {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-color: auto !important;
    scrollbar-width: none;
}

:root {
    --p-space-250: $space-250;
    --p-space-350: $space-350;
    --p-space-450: $space-450;
    --p-space-550: $space-550;
}

.trustshop-app--container {
    height: 100vh;
    overflow: scroll;
    scrollbar-width: thin;
    width: 100%;
}

.trustshop-app--wrap {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        "Inter", monospace;
}
#root {
    height: 100%;
}

.limit-function {
    filter: grayscale(1);
    p,
    label,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--p-color-text-disabled) !important;
    }
    // .Polaris-ShadowBevel{
    //     background-color: var(--p-color-bg-fill-disabled)
    // }
}
.badge-limit {
    &:hover {
        .badge-limit--content {
            display: block;
        }
    }
    .badge-limit--content {
        width: 200%;
        min-width: 100%;
        display: none;
        z-index: 999;
        &::before {
            content: "";
            width: 100%;
            height: 0.4rem;
            position: absolute;
            bottom: 100%;
        }
        .badge-limit--link {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.pricing-modal,
.custom-modal {
    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    &--wrap {
        width: fit-content;
        max-width: 90%;
        height: 90svh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        border-radius: 12px;
    }
}
.custom-filter-item--activator {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 2px 8px 2px 12px;
    border-radius: 8px;
    border: 1px dashed var(--p-color-border);
    cursor: pointer;
    p {
        line-height: 100%;
        max-width: 150px;
    }
}

.list-type--number {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background-color: #e3e3e3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

@import "var";

@import "login";
@import "review";
@import "responsive";
@import "setting";
@import "email";
@import "widget";
@import "onboarding";
@import "dashboard";
@import "import-review";
@import "send-mail-manual";
@import "widget-review-carousel";
@import "group-product";
@import "review-detail-page";
@import "qr-code-page";
@import "voucherDemo";
