// .import-review-page--title {
//     display: flex !important;
//     flex-direction: row;
//     flex-wrap: nowrap;
//     gap: 5px;
//     align-items: center;
// }

// .import-review {
//     .import-review--area {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }

//     .resourceItem {
//         padding: 10px;
//         cursor: pointer;
//         border: 1px solid #ccc;
//         border-radius: 4px;
//         transition:
//             background-color 0.3s ease,
//             border 0.3s ease;

//         &.selected {
//             background-color: #e0f7fa;
//             border: 2px solid #007bff;
//         }
//     }

//     .last-item {
//         list-style-type: none;
//     }
//     .Polaris-Page {
//         .product-table {
//             border-radius: 12px;
//             overflow: hidden;
//             border: 1px solid;
//             border-color: transparent #ebebeb #ebebeb;
//             .Polaris-IndexTable {
//                 padding: 0 0 0;
//                 .Polaris-IndexTable__IndexTableWrapper {
//                     border-radius: 5px;
//                     td {
//                         vertical-align: middle;
//                     }
//                     .product-info {
//                         padding-top: 6px;
//                         width: 25%;
//                         .Polaris-Text--root {
//                             max-width: 300px;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }
//                     }
//                     .product-review-count {
//                         width: 8%;
//                         .Polaris-Icon {
//                             margin: 0;
//                         }
//                     }
//                     .product-ali-link {
//                         max-width: 400px;
//                         min-width: 300px;
//                         p {
//                             width: 100%;
//                             -webkit-line-clamp: 1;
//                             -webkit-box-orient: vertical;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }
//                     }
//                 }
//             }
//             .Polaris-IndexTable__ScrollBarContainer {
//                 border-radius: unset;
//             }
//         }
//     }
// }

// .modal--overlay {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 400;
//     background-color: rgba(0, 0, 0, 0.5);
//     .modal--container {
//         min-width: 490px;
//         max-width: 490px;
//         height: fit-content;
//         box-sizing: border-box;
//         animation: showModal 0.2s ease-in-out;

//         .modal-import-setting--loading {
//             min-height: 490px;
//             display: grid;
//             place-content: center;
//         }

//         .close-modal--icon {
//             cursor: pointer;
//             &:hover {
//                 svg {
//                     path {
//                         fill: #030303;
//                     }
//                 }
//             }
//         }
//         @media (max-width: 490px) {
//             width: 90%;
//             min-width: unset;
//         }
//         .import-guild--line {
//             p {
//                 font-size: 13px;
//                 font-weight: 450;
//                 line-height: 20px;
//                 padding-left: 20px;
//                 position: relative;
//                 &::before {
//                     content: "";
//                     display: block;
//                     width: 5px;
//                     height: 5px;
//                     border-radius: 50%;
//                     background-color: #030303;
//                     position: absolute;
//                     left: 5px;
//                     top: 10px;
//                     transform: translateY(-50%);
//                 }
//                 &:first-child {
//                     padding: unset;
//                     &::before {
//                         display: none;
//                     }
//                 }
//             }
//         }
//         .list-product--wrap {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             padding: 0 0 16px;
//             height: fit-content;
//             max-height: 80vh;
//             box-sizing: content-box;
//             overflow: auto;
//             .product-item {
//                 padding: 0;
//                 padding: 0 16px;
//                 cursor: pointer;
//                 .product-item--wrap {
//                     padding: 6px 0;
//                     border-bottom: 1px solid #ebebeb;
//                     .Polaris-Text--root {
//                         max-width: 380px;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 2;
//                         -webkit-box-orient: vertical;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }
//                 &:last-child {
//                     .product-item--wrap {
//                         border-bottom: unset;
//                     }
//                 }
//                 &:hover {
//                     background-color: #f1f1f1;
//                 }
//             }
//         }

//         .modal-countries--tag {
//             max-height: 80px;
//             overflow-y: auto;
//             padding-right: 4px;
//             &::-webkit-scrollbar {
//                 width: 4px;
//             }
//             &::-webkit-scrollbar-thumb {
//                 background-color: #757474;
//                 border-radius: 4px;
//             }
//             &::-webkit-scrollbar-track {
//                 background-color: #f1f1f1;
//                 border-radius: 4px;
//             }
//         }
//     }
// }

// @keyframes showModal {
//     from {
//         opacity: 0.5;
//         transform: translateY(75%);
//     }
//     to {
//         opacity: 1;
//         transform: translateY(0);
//     }
// }

.import-review--container {
    padding: 0 170px;

    @media (max-width: 1366px) {
        padding: 0 100px;
    }

    @media (max-width: 1024px) {
        padding: 0 50px;
    }

    @media (max-width: 768px) {
        padding: 0 20px;
    }

    @media (max-width: 480px) {
        padding: 0 10px;
    }

    .import-review {
        .platform-container {
            display: flex;
            flex-direction: column;
            height: 350px;

            ::-webkit-scrollbar {
                width: 11px;
            }

            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 10px;
                border: 3px solid #f1f1f1;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .scrollbar-custom {
                scrollbar-width: thin;
                scrollbar-color: #888 #f1f1f1;
            }

            .list-platform {
                margin: 10px 0 5px 0;
                flex: 1 1 auto;
                overflow-y: auto;
                height: 500px;
            }
        }

        .dropzone-container {
            .drop-zone {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 5px 0 0 0;

                .drop-zone--hint {
                    margin: 0 0 35px 0;
                    display: flex;
                    flex-direction: column;
                }
            }

            .import--field {
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 25vh;
            }
        }

        .suggestion {
            display: flex;
            padding: 5px 10px;
            flex-direction: column;
            border-radius: 8px;
            background-color: rgb(239, 241, 241);
        }

        .suggestion-title {
            font-weight: 700;
        }

        .suggestion-content {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
        }

        .suggestion-item {
            padding: 0px 5px;
        }

        .ispid-content {
            display: flex;
            flex-direction: column;
        }

        .ispid-link {
            color: rgb(0, 127, 255);
            text-decoration: none;

            p {
                margin: 5px 0 0 0;
            }
        }
        .import-review-from-custom--csv {
            &:hover {
                .limit-function--tooltip {
                    display: block;
                }
            }
            .limit-function--tooltip {
                display: none;
                position: absolute;
                top: calc(100% + 4px);
                background-color: #ffffff;
                z-index: 999;
                border-radius: 12px;
                box-shadow: 0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
                    -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
                    0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset,
                    0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset,
                    0rem 0.25rem 0.375rem -0.125rem rgba(26, 26, 26, 0.2);
                padding: 8px 10px;
                &::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #ffffff;
                    bottom: 98%;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 9;
                    filter: drop-shadow(
                        0 -0.075rem 0 rgba(26, 26, 26, 0.1)
                    );
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: transparent;
                    bottom: 100%;
                    left: 0;
                }
            }
        }
    }
}
