.carousel{
    & > .Polaris-InlineGrid {
        height: 100%;
        overflow: hidden;
        .review-testimonial-setting--field{
            height: 100%;
                max-height: 1080px;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                border-right: 1px solid #ebecee;
                background-color: #ffffff;
                transition: height 0.3s linear;
                &.hide {
                    height: 53px;
                    @media (min-width: 1040px) {
                        height: 100%;
                    }
                }
                @media (max-width: 1039px) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50vh;
                    z-index: 399;
                    border-top: 1px solid #ebecee;
                    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
                }
                &::-webkit-scrollbar {
                    display: none;
                }
        }
    }
}
.review-carousel-demo--wrap {
    margin: auto;
    max-height: 1080px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    &.mobile {
        border-radius: 12px;
        overflow: hidden;
        width: 390px;
        .headline-mobile {
            font-size: 10px;
        }
    }
}
.review-carousel-demo--field {
    .demo-container {
        justify-content: center;
        width: 100%;
        .Polaris-BlockStack {
            .Polaris-Box {
                background-color: #ffffff;
                width: 100%;
            }
        }

        .review-carousel-demo {
            padding: 38px 20px;
            height: 100%;
            width: 100%;
            background-color: var(--widget_background);
            margin: auto;
            min-height: 50vh;

            @media (max-width: 1024px) {
                padding: 4vh 4vw;
                min-height: 45vh;
            }

            @media (max-width: 768px) {
                padding: 3vh 3vw;
                min-height: 40vh;
            }

            @media (max-width: 480px) {
                padding: 2vh 2vw;
                min-height: 35vh;
            }

            .review-carousel-demo--content--heading {
                font-family: "Inter", sans-serif;
                margin-bottom: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;
                p {
                    text-align: center;
                    font-weight: 400;
                    font-size: 29px;
                    line-height: 100%;
                    color: var(--headline);
                    margin: 0;
                }
            }

            .review-carousel-demo--section {
                position: relative;
                width: 90%;
                margin: auto;
                padding: 0px 16px;
                .carousel-button {
                    position: absolute;
                    width: 28px;
                    height: 52px;
                    top: 50%;
                    border-radius: 12px;
                    overflow: hidden;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: transparent;
                    &.left {
                        left: -6px;
                        @media (max-width: 600px) {
                            left: -2px;
                        }
                    }

                    &.right {
                        right: -28px;
                        @media (max-width: 600px) {
                            right: -2px;
                        }
                    }

                    span {
                        display: block;
                        background-color: var(--navigator_button) !important;
                        opacity: 0.5;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                    svg {
                        position: relative;
                    }
                }
            }

            .review-carousel-demo--content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                box-sizing: border-box;
                width: fit-content;
                margin: auto;
                padding: 0 16px;
                .review-carousel-demo--card {
                    overflow: hidden;
                    height: 295px;
                    width: 300px;
                    .card {
                        border-radius: 5px;
                        background-color: var(--card_background);
                        border: 1px solid var(--stroke);
                        padding: 16px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    .user {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px 0;

                        .customer-name {
                            color: var(--customer_name);
                            font-size: 14px;
                            font-weight: 600;
                        }

                        .name-without-stars {
                            margin-top: 40px;
                        }
                        .hidden-star {
                            display: none;
                        }
                        .stars.visible {
                            display: flex;
                             flex-direction: row;
                             flex-wrap: nowrap;
                        }
                        .stars {
                            svg {
                                width: 12.16px;
                                height: 12.16px;
                                path {
                                    fill: var(--star);
                                }
                            }
                        }
                    }
                }

                .content {
                    width: 100%;
                    display: grid;
                    place-items: center;
                    color: var(--content);
                    svg {
                        margin-bottom: 15px;
                        path {
                            fill: var(--content);
                        }
                    }

                    p {
                        font-size: 14px;
                        text-align: center;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}

.mobile-hidden {
    @media (max-width: 599px) {
        display: none;
    }
}

.modal-choose-review {
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 7px;
        border-bottom: 1px solid #ccc;
        .checkbox {
            flex: 2.2;
            margin-top: 0px;
        }

        .filters {
            display: flex;
            flex: 9.8;
            border-bottom: 10px #ccc;

            .Polaris-Box {
                border: none;
            }
            .Polaris-TextField {
                width: 100%;
                margin-left: 0;
            }

            .Polaris-Filters__FiltersInner {
                border: 0;
            }
        }
    }

    .modal-body {
        padding: 20px 15px;

        .Polaris-BlockStack {
            width: 100%;
        }

        .row {
            display: flex;
            padding: 20px 0;
            border-bottom: 1px solid #ccc;

            .header {
                .rating {
                    display: flex;
                    align-items: center;

                    .rating-number {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                        padding-right: 5px;
                    }
                    .rating-star {
                        display: flex;
                        padding: 2px;
                        color: #ffa902;
                    }
                }
            }

            .body {
                display: flex;
                align-items: center;
                margin-top: 10px;
                max-width: 100%;

                .product-images {
                    margin-left: 28px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    width: 40px;
                    height: 40px;
                }

                .product-info {
                    margin-left: 10px;
                    .title {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }

            .footer {
                margin-top: 20px;
                margin-left: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .list-images {
                    left: 0;
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    margin-left: 10px;

                    .image {
                        width: 40px;
                        height: 40px;
                    }
                }

                .customer-info {
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 5px;

                    .personal-info {
                        display: block;
                        align-items: end;
                        gap: 5px;
                        .name {
                            text-align: right;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }

                    .date {
                        font-size: 12px;
                        margin-right: 20px;
                    }

                    .Polaris-Avatar {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.widget-setting--field {
    position: relative;
    height: 100%;
    max-height: 1080px;


    .review-carousel-setting--item {
        padding: 10px 0;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: fit-content;

        .warning-zone {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ccc;
            padding: 15px;
            border-radius: 5px;
            gap: 16px;

            .warning-icon {
                margin-top: 5px;
            }

            .warning-text {
                flex: 1;
                font-size: 13px;
                display: inline;
                align-items: center;
                gap: 2px;

                a {
                    color: #134bd9;
                }

                a:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .warning-close {
                p {
                    cursor: pointer;
                }
            }
        }
    }
}
